export const clmDrilldownchart = (data1,data2,label) => {
    return {
          chart: {
              type: 'column',
              marginTop:50
          },
          title: {
              text: ''
          },
          xAxis: {
              type: 'category'
          },
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
            labels: {
              format: label == "percent" ? "{value}%" : "{value}",
            },
          },
          credits: {
            enabled: false
            },

           
            tooltip: {
                enabled: true,
                headerFormat: "",
                pointFormat:
                label == "percent"?
                  '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><b>%</b><br/>':
                label == "number"?
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><b></b><br/>':""
              },
    
          legend: {
              enabled: false
          },
          lang: {
            drillUpText: "◁ Back",
          },
          plotOptions: {
              series: {
                  borderWidth: 0,
                  dataLabels: {
                      enabled: true,
                      format: label == "percent" ? "{y}%":"{y}"
                  }
              }
          },
          series: [{
              name: 'Home',
              colorByPoint: true,
              data: data1,
              colors:[
                "#4472c4",
                "#ed7d32",
                "#35a98a",
                "#ffc100",
                "#5b9bd5",
                "#71ad47",
              ],
          }],
          drilldown: {
            drillUpButton: {
               relativeTo: 'spacingBox',
               position: {
               align: 'right',
               x: 0,
               y: 0
               }
              },
              activeAxisLabelStyle: {
                  textDecoration: 'none',
                  fontStyle: 'italic'
              },
              activeDataLabelStyle: {
                  textDecoration: 'none',
                  fontStyle: 'italic'
              },
              series: data2
          },
          exporting:{
            enabled:false
          }
      }
    } 
