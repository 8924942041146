import axios from "axios";
import {
  showAlert,
  successWarning,
  slownetworkWarning,
} from "../components/core/errorhandling";
import * as URL from "../services/urls";
import AesUtil from "./AESUtil";
import lsencryption from "./lsencryption";

const aesUtil = new AesUtil(256, 1000);

var NETWORKPOPUP = false;

const logout = () => {
  deleteCookie("auth-token");
  localStorage.removeItem("community");
  let url = `${window.location.origin}/`;
  window.location.replace(url);
};

const setCookie = (cname, cvalue, expmins) => {
  // var d = new Date();
  // d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  // d.setTime(d.getTime() + (expmins * 60 * 1000));
  // var expires = "expires="+d.toUTCString();
  // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  document.cookie =
    cname + "=" + cvalue + ";path=/;Secure;SameSite=Strict;HttpOnly";
  lsencryption.set(cname, cvalue);
};

const getCookie = (cname) => {
  /* var name = cname + "=";
  var ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return ""; */
  return lsencryption.get(cname);
};

const deleteCookie = (cname) => {
  document.cookie = `${cname} = expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;Secure;SameSite=Strict;HttpOnly`;
  localStorage.removeItem(cname);
};

const ignoreEncryption = (p_url) => {
  let aUrlsList = [];
  return aUrlsList.indexOf(p_url) > -1;
};

const instance = axios.create({
  baseURL: URL.apiUrl,
  headers: { "Content-Type": "application/json" },
  /* headers: { 
    'Content-Type': 'application/json' ,
    'Content-Security-Policy': 'self',
    'X-Content-Type-Options': 'nosniff',
    'X-XSS-Protection': "1; mode=block"
  } */
});

instance.interceptors.request.use(
  function (request) {
    let bUrl = request.url === URL.loginUrl,
      paramString = JSON.stringify(request.data),
      salt = aesUtil.generateSalt(),
      iv = aesUtil.generateiv(),
      sAuthparam = getCookie("auth-token");
    if (
      request.url != "https://debug.duretechnologies.com/openIssue_nextGen.php"
    ) {
      let encryptParam = bUrl
        ? { _dlt: aesUtil.encrypt(salt, iv, paramString) }
        : { _drp: aesUtil.encrypt(salt, iv, paramString) };
      if (!ignoreEncryption(request.url)) {
        request.data = request.data;
      }
      // if (true) {
      //   request.headers["auth-token"] =
      //     "eyJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE3MzQ1MjI1MDQsImlzcyI6IkFkbWluIiwiZXhwIjoxNzM0NTI5NzA0fQ.5AS2hAkrufj9j9GiFkF8wXUUVecQbbq2B4u_MlzYGpg"; //sAuthparam;
      // }
    }

    /* request.params.checkTimeout = setTimeout(() =>{
      if(!NETWORKPOPUP){
        NETWORKPOPUP = true;
        slownetworkWarning('Slow Network','Slow Network',(p_flag) => {
          NETWORKPOPUP = false;
          if(p_flag){
            logout();
          }
        }, ['Continue', 'Try after some time']);
      }
    },6000); */

    return request;
  },
  function (error, response) {
    showAlert();
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.info(response.config.params.checkTimeout)
    // clearTimeout(response.config.params.checkTimeout);
    let { headers, data, config } = response,
      authToken = headers["auth-token"];
    if (config.isFirebase) {
      return response;
    }
    if (data && data.status && data.status === 403) {
      successWarning(data.data, data.message, () => {
        logout();
      });
      return Promise.reject(data.message);
    } else {
      if (authToken) {
        // deleteCookie('auth-token');
        setCookie("auth-token", authToken, 10);
      }
      return response;
    }
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("error calling", error); //sucess
    let { response, code, message } = error;
    const errorObj = {
      component: "API",
      method: error.config.url,
      error: message,
    };
    // clearTimeout(response.config.params.checkTimeout);
    if (code === "ECONNABORTED") {
      console.log("error ECONNABORTED", code);
      const codeError = {
        component: "API",
        method: error.config.url,
        error: message,
      };
      successWarning(code, message, () => {
        logout();
      });
      return Promise.reject(error);
    } else if (!response.data.error) {
      return Promise.resolve(response);
    } else {
      showAlert();
      return Promise.reject(error);
    }
  }
);

export default instance;
