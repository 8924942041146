export const trendchartdropdown = [
    {
        "value":3,
        "label":"Top 3"
    },
    {
        "value":5,
        "label":"Top 5"
    }

]