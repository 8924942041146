import React, { useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import ChartComponent from "../HighCharts/ChartComponents";
import { Alert } from "reactstrap";
import API from "../../services";
import * as URL from "../../services/urls";
import { trendchartdropdown } from "../../services/DropdownUtils";
import Chartloader from "../../services/Chartloader";
import moment from "moment";
import { clmDrilldownchart } from "./clmchartconfig";
import Highchartdrilldown from "./Highchartdrilldown";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);

function AAQchart({uid}) {
  const elementRef = useRef(null);
  const [t] = useTranslation();
  const hChartsubSection = useRef();
  const [loading, setLoading] = useState(true);
  const [drilldownchartdata, setDrillDownChartData] = useState({});
  const [drilldowncharttype, setDrilldowncharttype] = useState("percent");
  const [isDrillDownMap, setIsDrillDownMap] = useState(false);

  useEffect(() => {
    let oRequest = {
      communityId: uid,
      languageId: 1,
      locationFilters: {},
      roleId: 53,
      isDefaultFilters: true,
      
    };
    let oReqDrilldownchartData = API.post(URL.aaaqIndicatorWise, oRequest);
    setLoading(true);
    Promise.all([oReqDrilldownchartData])
      .then(([oDrilldownchartData]) => {
        let drilldownchartDataRespose;
        if (
          oDrilldownchartData &&
          oDrilldownchartData.data &&
          oDrilldownchartData.data.data.aaaqResponse != null
        ) {
          drilldownchartDataRespose =
            oDrilldownchartData.data.data.aaaqResponse;
        } else if (
          oDrilldownchartData &&
          oDrilldownchartData.data &&
          oDrilldownchartData.data.data.aaaqResponseData.aaaqResponse != null
        ) {
          drilldownchartDataRespose =
            oDrilldownchartData.data.data.aaaqResponseData.aaaqResponse;
        } else {
          drilldownchartDataRespose = [];
        }

        setDrillDownChartData(drilldownchartDataRespose);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [uid]);

  let drillDownChart = (data, flag) => {
    let noDrillArr = [];
    let secondLayerArr = [];
    let topLayerArr = [];
    let percentTopLayer = [];
    let percent = false;

    // instead of Object.keys(data) we are hardcoing this array for proper positioning

    let positionArr = [
      "Availability",
      "Accessibility",
      "Acceptability",
      "Quality",
    ];

    positionArr.forEach((mainElement) => {
      let innerLayer = [];

      if (data[mainElement] !== undefined || data[mainElement] !== null) {
        let sum = data[mainElement]
          ? (Object.values(data[mainElement]) || []).reduce((a, b) => a + b, 0)
          : 0;
        noDrillArr = [...noDrillArr, sum];
        topLayerArr = [
          ...topLayerArr,
          {
            name: t(mainElement),
            y: sum,
            drilldown: t(mainElement),
          },
        ];
        data[mainElement] &&
          Object.keys(data[mainElement]).map((innerLayerKeys) => {
            if (flag == "percent") {
              let calculatePercent = Math.round(
                (data[mainElement][innerLayerKeys] / sum) * 100
              );
              innerLayer = [...innerLayer, [innerLayerKeys, calculatePercent]];
            } else {
              innerLayer = [
                ...innerLayer,
                [innerLayerKeys, data[mainElement][innerLayerKeys]],
              ];
            }
          });

        let translatedArr = [];
        innerLayer &&
          innerLayer.map((ele) => {
            translatedArr = [...translatedArr, [t(ele[0]), ele[1]]];
          });

        secondLayerArr = [
          ...secondLayerArr,
          {
            id: t(mainElement),
            // data: innerLayer
            data: translatedArr,
          },
        ];
      }
    });

    let total = noDrillArr.reduce((a, b) => a + b, 0);
    topLayerArr.forEach((value) => {
      percentTopLayer = [
        ...percentTopLayer,
        {
          name: t(value.name),
          y: isNaN(Math.round((value.y / total) * 100))
            ? 0
            : Math.round((value.y / total) * 100),
          drilldown: t(value.drilldown),
        },
      ];
    });

    let arr = [];
    if (flag == "percent") {
      arr = [...percentTopLayer];
    } else {
      arr = [...topLayerArr];
    }
    let oChartConf = clmDrilldownchart(arr, secondLayerArr, flag);
    return oChartConf;
  };

  return (
    <div
      ref={elementRef}
      className="col-6 col-md-12 col-lg-12 col-xl-6"
      id="chartContainer"
    >
      <Card className="text-left">
        <Card.Header className="aaaq-chart">
          <span
            className="cardtitle"
            title={t(
              `Distribution of challenges reported as care delivery barriers`
            )}
          >
            {t(`Distribution of challenges reported as care delivery barriers`)}
          </span>
          <div className="tooltipdivmain">
            <div>
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  t(
                    "Distribution of challenges reported as care delivery barriers icon"
                  )
                )}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="mb-10px percent-number-button">
            <button
              disabled={isDrillDownMap}
              className={
                drilldowncharttype == "percent"
                  ? "active nav-link chartswitchlink"
                  : "nav-link chartswitchlink"
              }
              onClick={() => {
                setDrilldowncharttype("percent");
              }}
            >
              {t("%")}
            </button>
            <button
              disabled={isDrillDownMap}
              className={
                drilldowncharttype == "number"
                  ? "active nav-link chartswitchlink"
                  : "nav-link chartswitchlink"
              }
              onClick={() => {
                setDrilldowncharttype("number");
              }}
            >
              {t("#")}
            </button>
          </div>
          <div id="natchartholder" className="natchartholder">
            {loading ? (
              <Chartloader />
            ) : loading == false &&
              Object.keys(drilldownchartdata).length > 0 ? (
              <Highchartdrilldown
                options={drillDownChart(drilldownchartdata, drilldowncharttype)}
                setIsDrillDownMap={setIsDrillDownMap}
              />
            ) : (
              <Alert
                variant="warning"
                className="text-center alert-warning my-3"
              >
                {t("Data not available!")}
              </Alert>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default AAQchart;
