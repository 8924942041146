import { React, useState } from "react";
import styled from "@emotion/styled/macro";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  FormControl,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@mui/material";

import ChartComponent from "../../components/HighCharts/ChartComponents";

// ICONS iMPORT
import phoneIcon from "../../assets/images/feedbackIcons/telephone.svg";
import messageIcon from "../../assets/images/feedbackIcons/mail.svg";
import boxIcon from "../../assets/images/feedbackIcons/box.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useLanguage } from "../../contexts/LanguageContext";
import feedbacktranslation from "../../config/feedbacktranslation";

// icons import
import peopleIcon from "../../assets/images/homepageIcons/people.svg";
import deadBodyIcon from "../../assets/images/homepageIcons/dead-body.svg";
import medicalIcon from "../../assets/images/homepageIcons/pills-bottle.svg";
import searchIcon from "../../assets/images/homepageIcons/search.svg";
import redArrowIcon from "../../assets/images/homepageIcons/reduparrow.svg";
import greenDownArrowIcon from "../../assets/images/homepageIcons/greendownarrow.svg";
import lightRedUpArrowIcon from "../../assets/images/homepageIcons/lightreduparrow.svg";
import targetMainIcon from "../../assets/images/homepageIcons/target.svg";
import infoIcon from "../../assets/images/homepageIcons/infoicon.svg";
import coverageIcon from "../../assets/images/homepageIcons/medical.svg";
import translations from "../../config/hometranslation";

import Trendchart from "../../components/oneimpact/TrendChart";
import AAQchart from "../../components/oneimpact/AAQchart";
import Drilldownchart from "../../components/oneimpact/Drilldownchart";
import SearchIcon from "@mui/icons-material/Search";

// custom textfield ui
const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E8E7E7", // Change this to your desired focused border color
  },
}));

function OneImpactPage() {
  // select country component
  const { language } = useLanguage();
  const [feedbackArea, setFeedbackArea] = useState(10); // Set the initial value to 10 for "Select country"
  const [queryParameterForCountry, setSelectedCountry] = useState();
  const [mainCountries, setMainCountries] = useState(
    "cea24f970711b002b6175e99f79c3bd4"
  );
  const t = (key) => feedbacktranslation[language][key] || key;

  const countries = [
    {
      name: "Cameroon",
      logo: imgUrl.cameroonLogo,
      value: "cea24f970711b002b6175e99f79c3bd4",
    },
    {
      name: "Benin",
      logo: imgUrl.beninLogo,
      value: "5c633357968f34df528f8f0ed69ae46e",
    },
    {
      name: "DRC",
      logo: imgUrl.rdcLogo,
      value: "0341d6fe0c71de07c04f770d5f50de91",
    },
    { name: "Congo", logo: imgUrl.congoLogo, value: 50 },
    {
      name: "Niger",
      logo: imgUrl.nigerLogo,
      value: "fb8be77016533a331a4f2a197fc35515",
    },
    {
      name: "Burkina Faso",
      logo: imgUrl.burkinaLogo,
      value: "1f38dfc7bc5cdd48c3137b05fcb46a9b",
    },
    {
      name: "Guinée",
      logo: imgUrl.guineeLogo,
      value: "7c392184c9a3d0031a2f10ec5e9b6911",
    },
    { name: "Burundi", logo: imgUrl.burundiLogo, value: 90 },
    { name: "Togo", logo: imgUrl.togoLogo, value: 100 },
    { name: "Mali", logo: imgUrl.maliLogo, value: 101 },
    { name: "Mauritanie", logo: imgUrl.mauritanieLogo, value: 102 },
    { name: "Chad", logo: imgUrl.chadLogo, value: 103 },
    { name: "Sénégal", logo: imgUrl.senegalLogo, value: 104 },
    { name: "Gabon", logo: imgUrl.gabonLogo, value: 105 },
    { name: "Centrafrique", logo: imgUrl.centrafriqueLogo, value: 106 },
    {
      name: "Côte d’Ivoire",
      logo: imgUrl.coteLogo,
      value: "e98f1ddbb9a209caa860d3a204a5cc84",
    },
  ];

  const handleFeedbackAreaChange = (event) => {
    setFeedbackArea(event.target.value);
  };

  const handleClick = (value) => { };

  const handleMainCountriesChange = (event) => {
    setMainCountries(event.target.value);
  };

  const chart12 = {
    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    yAxis: {
      title: {
        text: "",
      },
    },

    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },

    legend: {},

    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },

    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
      series: {
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: [
      {
        name: "Treatment counselling not provided",
        color: "#4472c4",
        data: [25, 22, 20, 20, 19, 18, 16, 15, 14, 12, 10, 8],
      },
      {
        name: "Stigma at family level",
        color: "#ed7d32",
        data: [22, 24, 20, 18, 20, 16, 16, 12, 11, 16, 14, 8],
      },
      {
        name: "Stigma at community level",
        color: "#a5a5a5",
        data: [18, 14, 12, 11, 11, 10, 6, 6, 4, 4, 2, 0],
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="commonPageCardSubSection feedbackSectionPage">
      <Card className="mt-10px">
        <CardContent className="maincardcontentclm">
          {/* <Typography className="cardHeading ">
            {t("CLM Dashboard")}
          </Typography> */}
          <Grid
            item
            xs={12}
            lg={3}
            className="subCountries-mobile-d-none d-none"
          >
            <Card className="defineCountryHeight">
              <CardContent>
                <div>
                  <Typography className="text-center listCountryHeader">
                    Countries
                  </Typography>
                  <div className="listCountries">
                    <List>
                      {countries.map((country, index) => (
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            onClick={() => handleClick(country.value)}
                            className={`${parseInt(queryParameterForCountry) ===
                                country.value
                                ? "countryactive"
                                : ""
                              }`}
                          >
                            <ListItemIcon>
                              <img src={country.logo} alt={country.name} />
                            </ListItemIcon>
                            <ListItemText primary={country.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </div>
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}></Grid>
            <Grid item xs={12} lg={4} className="mt-16px">
              <div className="countryDropdown mt-0px">
                <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
                  <SearchIcon className="searchIcon"></SearchIcon>
                  <FormControl fullWidth className="countrySelectField">
                    <Select
                      labelId="demo-mainCountries-select-label"
                      id="demo-mainCountries-select"
                      value={mainCountries}
                      label=""
                      onChange={handleMainCountriesChange}
                    >
                      <MenuItem value={10} disabled>
                        Select Country
                      </MenuItem>
                      {countries.map((country) => (
                        <MenuItem key={country.value} value={country.value}>
                          <div className="d-flex justify-content-start align-items-center">
                            <img
                              src={country.logo}
                              alt={country.name}
                              style={{ marginRight: "10px" }}
                            />
                            <span className="countryName">{country.name}</span>
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>{" "}
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mt-5 clmdashboardcards">
            <Grid item xs={12} lg={3} className="leftSideCard">
              <Card>
                <CardContent className="pb-0px">
                  <div className="d-flex justify-content-start align-items-center">
                    <IconButton
                      variant="contained"
                      className="lightblueButton pb-0px"
                    >
                      <img src={peopleIcon}></img>
                    </IconButton>
                    <Typography className="leftCardHeading">
                      {t("TOTAL PEOPLE ENGAGED")}
                    </Typography>
                  </div>
                  <div className="d-flex justify-space-evenly align-items-center mt-10px">
                    <Typography className="indicatorCount">1120</Typography>
                    {/* <img src={redArrowIcon}></img>
                    <p className="indicatorDate">
                      <span className="indicatorSubCount">+2,489</span>{" "}
                      {t("since")}
                      <br></br>
                      {t("lastYear")}
                    </p> */}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} className="leftSideCard">
              <Card>
                <CardContent className="pb-0px">
                  <div className="d-flex justify-content-start align-items-center">
                    <IconButton
                      variant="contained"
                      className="lightblueButton pb-0px"
                    >
                      <img src={peopleIcon}></img>
                    </IconButton>
                    <Typography className="leftCardHeading">
                      {t("TOTAL PEOPLE REGISTERED")}
                    </Typography>
                  </div>
                  <div className="d-flex justify-space-evenly align-items-center mt-10px">
                    <Typography className="indicatorCount">1003</Typography>
                    {/* <img src={redArrowIcon}></img>
                    <p className="indicatorDate">
                      <span className="indicatorSubCount">+2,489</span>{" "}
                      {t("since")}
                      <br></br>
                      {t("lastYear")}
                    </p> */}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} className="leftSideCard">
              <Card>
                <CardContent className="pb-0px">
                  <div className="d-flex justify-content-start align-items-center">
                    <IconButton
                      variant="contained"
                      className="lightblueButton pb-0px"
                    >
                      <img src={peopleIcon}></img>
                    </IconButton>
                    <Typography className="leftCardHeading">
                      {t("REPORTED CHALLENGES")}
                    </Typography>
                  </div>
                  <div className="d-flex justify-space-evenly align-items-center mt-10px">
                    <Typography className="indicatorCount">199</Typography>
                    {/* <img src={redArrowIcon}></img>
                    <p className="indicatorDate">
                      <span className="indicatorSubCount">+2,489</span>{" "}
                      {t("since")}
                      <br></br>
                      {t("lastYear")}
                    </p> */}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={3} className="leftSideCard">
              <Card>
                <CardContent className="pb-0px">
                  <div className="d-flex justify-content-start align-items-center">
                    <IconButton
                      variant="contained"
                      className="lightblueButton pb-0px"
                    >
                      <img src={peopleIcon}></img>
                    </IconButton>
                    <Typography className="leftCardHeading">
                      {t("REPORTED CHALLENGES")}
                    </Typography>
                  </div>
                  <div className="d-flex justify-space-evenly align-items-center mt-10px">
                    <Typography className="indicatorCount">0(0% )</Typography>
                    {/* <img src={redArrowIcon}></img>
                    <p className="indicatorDate">
                      <span className="indicatorSubCount">+2,489</span>{" "}
                      {t("since")}
                      <br></br>
                      {t("lastYear")}
                    </p> */}
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} className="leftSideCard">
              <Card className="text-left mapseccard">
                <CardContent className="clmseccards">
                  <Trendchart uid={mainCountries}></Trendchart>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} className="leftSideCard">
              <Card className="text-left mapseccard">
                <CardContent className="clmseccards">
                  <AAQchart uid={mainCountries}></AAQchart>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} lg={12} className="leftSideCard">
              <Card className="text-left mapseccard">
                <CardContent className="clmseccards">
                  <Drilldownchart uid={mainCountries}></Drilldownchart>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default OneImpactPage;
