import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Home from "./pages/home";
import CRG from "./pages/crg";
import UNHLM from "./pages/unhlm";
import UnhlmScorecard from "./pages/unhlmscorecard";
import Feedback from "./pages/feedback";
import { LanguageProvider } from "./contexts/LanguageContext";

import CRGAssessmentDashboard from "./pages/CRGPage/CRGAssessmentDashboard/CRGAssessmentDashboard";
import CRGActionPlanDashboard from "./pages/CRGPage/CRGActionPlanDashboard/CRGActionPlanDashboard";
import UNHLMAccountabilityDashboard from "./pages/UNHLMPage/UNHLMAccountabilityDashbaord/UNHLMAccountabilityDashboard";
import UNHLMActivitiesDashboard from "./pages/UNHLMPage/UNHLMActivitiesDashboard/UNHLMActivitiesDashboard";
import OneImpact from "./pages/oneimpact";

function App() {
  return (
    <div className="App">
      <Router basename="/">
        <LanguageProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/CRG" element={<CRG />} />
            <Route path="/UNHLM" element={<UNHLM />} />
            <Route path="/UnhlmScorecard" element={<UnhlmScorecard />} />
            <Route path="/Feedback" element={<Feedback />} />
            <Route path="/OneImpact" element={<OneImpact />} />

            {/* temporary routing */}
            <Route
              path="/CRGAssessmentDashboard"
              element={<CRGAssessmentDashboard />}
            />
            <Route
              path="/CRGActionPlanDashboard"
              element={<CRGActionPlanDashboard />}
            ></Route>

            <Route
              path="/UNHLMAccountabilityDashboard"
              element={<UNHLMAccountabilityDashboard />}
            />
            <Route
              path="/UNHLMActivitiesDashboard"
              element={<UNHLMActivitiesDashboard />}
            />
          </Routes>
        </LanguageProvider>
      </Router>
    </div>
  );
}

export default App;
