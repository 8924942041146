import React, { useEffect, useState, useCallback, useRef } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import Drilldown from "highcharts/modules/drilldown";
import { Alert } from "react-bootstrap";
require("highcharts/modules/exporting")(Highcharts);

if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
  Drilldown(Highcharts);
}

const Highchartdrilldown = ({ options, refrs, setIsDrillDownMap }) => {
  const [t] = useTranslation();

  if (options) {
    if (options.xAxis) {
      options.xAxis.gridLineWidth = 0;
      options.yAxis.gridLineWidth = 0;
    }
    // options.colors = [
    //     "#326D72",
    //     "#90ed7d",
    //     "#f7a35c",
    //     "#8085e9",
    //     "#f15c80",
    //     "#e4d354",
    //     "#2b908f",
    //     "#f45b5b",
    //     "#91e8e1",
    //   ];
    if (options.chart) {
      options.chart.events = {
        drilldown: (e) => {
          setIsDrillDownMap(true);
          /* refrs.current.chart.update({
            plotOptions: {
              column: {
                dataLabels: {
                  format: "{point.y}",
                },
              },
            },
          }); */
        },
        drillupall: (e) => {
          setIsDrillDownMap(false);
        },
      };
    }
  }

  return (
    <Card className={`bg-darkblue border-0`}>
      <CardBody>
        <div className="chart-wrapper">
          {options ? (
            <HighchartsReact
              highcharts={Highcharts}
              allowChartUpdate={true}
              options={options}
              ref={refrs}
            />
          ) : (
            <Alert variant="warning" className="text-center my-3">
              {t("Data not available")}
            </Alert>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default Highchartdrilldown;
