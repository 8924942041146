import swal from 'sweetalert';

export const showAlert = (p_err) =>{
    console.log('p_err>>', p_err)
    let bLogout = typeof p_err === 'string'
    swal({
        title: "Something went wrong!!!",
        text: bLogout ? p_err :"Please Contact your Administrator." ,
        icon: "error",
        button: "OK"
    }).then((willDelete) => {
        // if(bLogout) {
            // localStorage.removeItem('community');
            // let url = `${window.location.origin}/imonitorplusunaids/`
            // window.location.replace(url);
        // } 
    });
}


export const showErrorMessage = (p_text,p_title,p_callb) =>{
    swal({
        title: p_text,
        text: p_title,
        icon: "error",
        button: "OK"
    }).then((willDelete) => {
        if(p_callb) p_callb(willDelete); 
    });
}


export const successAlert = (p_text,p_callb,p_title) =>{
    swal({
        title: p_title ? p_title : "Success",
        text: p_text,
        icon: "success"
    }).then((willDelete) => {
        p_callb(willDelete);
    });
}

export const successWarning = (p_title,p_text,p_callb,p_buttons) =>{
    swal({
        title:p_title,
        text: p_text,
        icon: !p_buttons ? "warning" : 'success',
        buttons: p_buttons ? p_buttons : {
            cancel: true,
            confirm: true,
        },
    }).then((willDelete) => {
        if(p_callb){
            p_callb(willDelete);
        }
    });
}

export const slownetworkWarning = (p_title,p_text,p_callb,p_buttons) =>{
    swal({
        title:p_title,
        text: p_text,
        icon:  "warning",
        buttons: p_buttons ? p_buttons : {
            cancel: true,
            confirm: true,
        },
    }).then((willDelete) => {
        if(p_callb){
            p_callb(willDelete);
        }
    });
}