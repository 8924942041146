import { React, useEffect, useState, useMemo } from "react";
import styled from "@emotion/styled/macro";
import {
  Card,
  MenuItem,
  FormControl,
  Select,
  TextField,
  CardContent,
  Typography,
  Grid,
} from "@mui/material";
import { groupBy } from "lodash";

import phoneIcon from "../../assets/images/feedbackIcons/telephone.svg";
import messageIcon from "../../assets/images/feedbackIcons/mail.svg";
import boxIcon from "../../assets/images/feedbackIcons/box.svg";
import imgUrl from "../../assets/images/imgUrl";
import { useLanguage } from "../../contexts/LanguageContext";
import feedbacktranslation from "../../config/feedbacktranslation";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import { Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ContactUs } from "../../components/ContactUs";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import axios from "axios";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E8E7E7",
  },
}));

function FeedbackPage() {
  const { language } = useLanguage();
  const [feedbackArea, setFeedbackArea] = useState(10);
  const [searchParams] = useSearchParams();
  const t = (key) => feedbacktranslation[language][key] || key;
  const [queryParameterForCountry, setSelectedCountry] = useState();

  const [formData, setFormData] = useState({
    areaoffeedback: "",
    areaoffeedback: language == 20 ? "Health System" : "Système de santé",
    subsection: "",
    firstname: "",
    lastname: "",
    country: "",
    email: "",
    comment: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState([]);

  const [filterArea, setFilterArea] = useState("");
  const [filterSubsection, setFilterSubsection] = useState("");

  const countries = [
    { name: "Cameroon", logo: imgUrl.cameroonLogo, value: 20 },
    { name: "Benin", logo: imgUrl.beninLogo, value: 30 },
    { name: "DRC", logo: imgUrl.rdcLogo, value: 40 },
    { name: "Congo", logo: imgUrl.congoLogo, value: 50 },
    { name: "Niger", logo: imgUrl.nigerLogo, value: 60 },
    { name: "Burkina Faso", logo: imgUrl.burkinaLogo, value: 70 },
    { name: "Guinée", logo: imgUrl.guineeLogo, value: 80 },
    { name: "Burundi", logo: imgUrl.burundiLogo, value: 90 },
    { name: "Togo", logo: imgUrl.togoLogo, value: 100 },
    { name: "Mali", logo: imgUrl.maliLogo, value: 101 },
    { name: "Mauritanie", logo: imgUrl.mauritanieLogo, value: 102 },
    { name: "Chad", logo: imgUrl.chadLogo, value: 103 },
    { name: "Sénégal", logo: imgUrl.senegalLogo, value: 104 },
    { name: "Gabon", logo: imgUrl.gabonLogo, value: 105 },
    { name: "Centrafrique", logo: imgUrl.centrafriqueLogo, value: 106 },
    { name: "Côte d’Ivoire", logo: imgUrl.coteLogo, value: 107 },
  ];

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountryForChart] = useState("");

  const [healthSystemOptions, setHealthSystemOptions] = useState([0, 0, 0, 0]);
  const [healthSystemCategory, setHealthSystemCategory] = useState([
    "Item 1",
    "Item 2",
    "Item 3",
    "Item 4",
  ]);

  const [crgOptions, setCrgOptions] = useState([0, 0, 0, 0]);
  const [crgCategory, setCrgCategory] = useState([
    "STIGMA",
    "CLM",
    "KVP",
    "HUMAN RESOURCE",
  ]);

  const [unhlmOptions, setUnhlmOptions] = useState([0, 0, 0, 0, 0, 0]);
  const [unhlmCategory, setUnhlmCategory] = useState([
    "KEY ASK #1",
    "KEY ASK #2",
    "KEY ASK #3",
    "KEY ASK #4",
    "KEY ASK #5",
    "KEY ASK #6",
  ]);
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("Feedback from DrafTB application");
  const generateColumnChart = (
    title,
    category,
    data,
    seriesName,
    color = "#26c6f9"
  ) => {
    return {
      chart: {
        type: "column",
      },
      title: {
        text: title,
        style: {
          fontSize: "18px",
        },
      },
      xAxis: {
        categories: category,
        crosshair: true,
        accessibility: {
          description: "Countries",
        },
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: "Numbers",
        },
      },
      tooltip: {
        valueSuffix: "",
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          color: color,
        },
      },
      series: [
        {
          name: seriesName,
          data: data,
        },
      ],
      credits: {
        enabled: false,
      },
    };
  };

  const healthSytemColumnChart = (healthSystemOption) => {
    return generateColumnChart(
      "Country-wise Health System Statistics",
      healthSystemCategory,
      healthSystemOption,
      "Health System"
    );
  };

  const crgColumnChart = (crgOption) => {
    return generateColumnChart(
      "Country-wise CRG Statistics",
      crgCategory,
      crgOption,
      "CRG",
      "#4AC3C7" // Custom color for CRG
    );
  };

  const unhlmColumnChart = (unhlmOption) => {
    return generateColumnChart(
      "Country-wise UNHLM Statistics",
      unhlmCategory,
      unhlmOption,
      "UNHLM",
      "#0A8489" // Custom color for UNHLM
    );
  };

  useEffect(() => {
    const countryId = searchParams.get("id");
    setSelectedCountry(countryId);
  }, [searchParams]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "feedback"));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const handleAreaChange = (e) => {
    if (e.target.value === "clear") {
      setFilterArea("");
    } else {
      setFilterArea(e.target.value);
    }
    setFilterSubsection("");
  };

  const handleSubsectionChange = (e) => {
    if (e.target.value === "clear") {
      setFilterSubsection("");
    } else {
      setFilterSubsection(e.target.value);
    }
  };

  const filteredData = useMemo(() => {
    return users.filter((item) => {
      return (
        (filterArea ? item.areaoffeedback === filterArea : true) &&
        (filterSubsection ? item.subsection === filterSubsection : true)
      );
    });
  }, [users, filterArea, filterSubsection]);

  const columns = useMemo(
    () => [
      { Header: t("areaoffeedback"), accessor: "areaoffeedback" },
      { Header: t("subsection"), accessor: "subsection" },
      { Header: t("country"), accessor: "country" },
      // { Header: t("firsname"), accessor: "firstname" },
      // { Header: t("lastname"), accessor: "lastname" },
      // { Header: t("email"), accessor: "email" },
      { Header: t("comment"), accessor: "comment" },
      {
        Header: t("timestamp"),
        accessor: "timestamp",
        Cell: ({ value }) => {
          const date = new Date(value.seconds * 1000);
          return date
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
            })
            .toUpperCase();
        },
      },
    ],
    [language]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize }, // You get pagination state from here
    page, // This is the paginated data (rows for the current page)
    gotoPage, // Function to navigate to a specific page
    canPreviousPage, // Whether the previous page is available
    canNextPage, // Whether the next page is available
    pageCount, // Total page count
    setPageSize,
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageSize: 10 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    if (
      !formData.firstname ||
      !formData.lastname ||
      !formData.email ||
      !formData.comment ||
      !formData.areaoffeedback ||
      !formData.subsection ||
      !formData.country
    ) {
      setError(t("validation"));
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setError("Please enter a valid email address.");
      return false;
    }
    return true;
  };

  useEffect(() => {
    GetFbData();
  }, []);

  const GetFbData = () => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "feedback"));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  };

  const sendEmail = async (
    name,
    areaoffeedback,
    subsection,
    country,
    email,
    comment
  ) => {
    try {
      let text = `Name: ${name}, Area of Feedback: ${areaoffeedback}, Subsection: ${subsection}, Country: ${country}, Email: ${email}, Comment: ${comment}`;
      const response = await axios.post("https://draftbdashboard.com/draftb-backend/send-email", {
        to,
        subject,
        name,
        areaoffeedback,
        subsection,
        country,
        email,
        comment,
      });
      //alert("Email sent successfully");
    } catch (error) {
      //alert("Error sending email");
      console.error(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (!validateForm()) {
      return;
    }

    try {
      await addDoc(collection(db, "feedback"), {
        areaoffeedback: formData.areaoffeedback,
        subsection: formData.subsection,
        firstname: formData.firstname,
        lastname: formData.lastname,
        country: formData.country,
        email: formData.email,
        comment: formData.comment,
        timestamp: new Date(),
      });

      sendEmail(
        `${formData.firstname} ${formData.lastname}`,
        formData.areaoffeedback,
        formData.subsection,
        formData.country,
        formData.email,
        formData.comment
      );

      setSuccess("Form submitted successfully!");
    } catch (err) {
      setError("Failed to submit form. Please try again.");
      console.error(err);
    } finally {
      GetFbData();

      setTimeout(() => {
        clearState();
        setSuccess("");
        setError("");
      }, 1200);
    }
  };

  const clearState = () => {
    setError("");
    setFormData({
      areaoffeedback: "",
      areaoffeedback: language == 20 ? "Health System" : "Système de santé",
      subsection: "",
      firstname: "",
      lastname: "",
      country: "",
      email: "",
      comment: "",
    });
  };

  const getChart = (e) => {
    setSelectedCountryForChart(e.target.value);

    setHealthSystemOptions([0, 0, 0, 0]);
    setCrgOptions([0, 0, 0, 0]);
    setUnhlmOptions([0, 0, 0, 0, 0, 0]);

    const filteredUsers = users.filter(
      (user) => user.country === e.target.value
    );

    if (filteredUsers.length === 0) {
      return;
    }

    const groupBasedonAreaOfFeedback = filteredUsers.reduce((acc, item) => {
      const areaoffeedback = item.areaoffeedback;
      if (!acc[areaoffeedback]) {
        acc[areaoffeedback] = [];
      }
      acc[areaoffeedback].push(item);
      return acc;
    }, {});

    const countSubsections = (subsections, categories) => {
      const counts = categories.reduce((acc, category) => {
        acc[category] = 0;
        return acc;
      }, {});

      subsections.forEach((item) => {
        if (counts.hasOwnProperty(item.subsection)) {
          counts[item.subsection]++;
        }
      });

      return categories.map((category) => counts[category]);
    };

    Object.keys(groupBasedonAreaOfFeedback).forEach((areaoffeedback) => {
      const subsectionsinArea = groupBasedonAreaOfFeedback[areaoffeedback];

      switch (areaoffeedback) {
        case "Health System":
          const healthSystemResult = countSubsections(
            subsectionsinArea,
            healthSystemCategory
          );
          setHealthSystemOptions(healthSystemResult);
          break;

        case "CRG":
          const crgResult = countSubsections(subsectionsinArea, crgCategory);
          setCrgOptions(crgResult);
          break;

        case "UNHLM":
          const unhlmResult = countSubsections(
            subsectionsinArea,
            unhlmCategory
          );
          setUnhlmOptions(unhlmResult);
          break;

        default:
          console.log(`Processing other areaoffeedback: ${areaoffeedback}`);
          break;
      }
    });
  };

  return (
    <div className="commonPageCardSubSection feedbackSectionPage">
      <Card>
        <CardContent>
          <p className="mb-0px mt-0px feedbackPara">{t("description")}</p>
        </CardContent>
      </Card>
      <Card className="mt-10px">
        <CardContent>
          <Typography>{t("title")}</Typography>
          <div className="countryDropdown w-30">
            <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
              <FormControl fullWidth className="countrySelectField ml-0px">
                <Select
                  labelId="demo-feedbackArea-select-label"
                  id="demo-feedbackArea-select"
                  label=""
                  name="areaoffeedback"
                  value={
                    formData.areaoffeedback == ""
                      ? t("menuitem1")
                      : formData.areaoffeedback
                  }
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {t("dropdownfeedback")}
                  </MenuItem>
                  <MenuItem value={t("menuitem1")} className="feedOption">
                    {t("menuitem1")}
                  </MenuItem>
                  <MenuItem value={t("menuitem2")} className="feedOption">
                    {t("menuitem2")}
                  </MenuItem>
                  <MenuItem value={t("menuitem3")} className="feedOption">
                    {t("menuitem3")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex justify-content-center align-items-center mt-10px selectFieldSection">
              <FormControl fullWidth className="countrySelectField ml-0px">
                <Select
                  labelId="demo-feedbackArea-select-label"
                  id="demo-feedbackArea-select"
                  label=""
                  name="subsection"
                  value={formData.subsection}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {t("dropdownSubSection")}
                  </MenuItem>
                  {feedbacktranslation[language][formData.areaoffeedback]?.map(
                    (x, index) => {
                      return (
                        <MenuItem key={index} value={x} className="feedOption">
                          {x}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="feedbackMsg mt-10px d-flex justify-content-between align-items-center feedbackMsgMobile">
            <div className="pt-0px w-20">
              <FormControl fullWidth className="countrySelectField ml-0px">
                <Select
                  labelId="demo-feedbackArea-select-label"
                  id="demo-feedbackArea-select"
                  label=""
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    {t("dropdowncountry")}
                  </MenuItem>
                  {countries?.map((x, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={x.name}
                        className="feedOption"
                      >
                        {x.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="pt-0px w-20">
              <CustomTextField
                id="outlined-first-static"
                label=""
                placeholder={t("firstname")}
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className=""
                size="small"
                required={true}
              />
            </div>
            <div className="pt-0px w-20">
              <CustomTextField
                id="outlined-last-static"
                label=""
                placeholder={t("lastname")}
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className=""
                size="small"
              />
            </div>
            <div className="pt-0px w-20">
              <CustomTextField
                id="outlined-email-static"
                label=""
                placeholder={t("emailaddress")}
                name="email"
                value={formData.email}
                onChange={handleChange}
                className=""
                size="small"
              />
            </div>
          </div>
          <div className="mt-10px feedbackMsg">
            <CustomTextField
              id="outlined-type-static"
              label=""
              placeholder={t("typehere")}
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              multiline
              className="w-100"
              rows={4}
            />
          </div>
          <div className="ml-auto d-flex justify-content-end align-items-center mt-16px">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-primary btn-submit"
            >
              Submit
            </button>
            <button
              onClick={clearState}
              type="button"
              className="btn btn-primary btn-clear"
            >
              Clear
            </button>
          </div>

          {error && <p style={{ color: "red" }}>{error}</p>}
          {success && <p style={{ color: "green" }}>{success}</p>}
        </CardContent>
      </Card>
      {
        //formSubmitted
        //&& (
        // <ContactUs
        //   from_name={formData.firstname}
        //   areaoffeedback={formData.areaoffeedback}
        //   subsection={formData.subsection}
        //   country={formData.country}
        //   email={formData.email}
        //   comment={formData.comment}
        // />
        //)
      }
      <Card className="mt-10px feedbacktablecard">
        <h2>{t("tabletitle")}</h2>
        <div className="feedbackdatafilters">
          <Select
            labelId="area-select-label"
            id="area-select"
            label="Filter by Area"
            value={filterArea || ""}
            onChange={handleAreaChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Filter by Area
            </MenuItem>
            <MenuItem value={t("menuitem1")} className="feedOption">
              {t("menuitem1")}
            </MenuItem>
            <MenuItem value={t("menuitem2")} className="feedOption">
              {t("menuitem2")}
            </MenuItem>
            <MenuItem value={t("menuitem3")} className="feedOption">
              {t("menuitem3")}
            </MenuItem>
            <MenuItem value="clear" className="feedOption">
              {t("menuitem4")}
            </MenuItem>
          </Select>

          <Select
            labelId="subsection-select-label"
            id="subsection-select"
            label="Filter by Subsection"
            value={filterSubsection || ""}
            onChange={handleSubsectionChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Filter by Subsection
            </MenuItem>
            {feedbacktranslation[language][filterArea]?.map((x, index) => {
              return (
                <MenuItem key={index} value={x} className="feedOption">
                  {x}
                </MenuItem>
              );
            })}
            <MenuItem value="clear">{t("menuitem4")}</MenuItem>
          </Select>
        </div>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups?.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers?.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells?.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="pagination-controls">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className="pagination-btn"
          >
            {"<<"}
          </button>
          <button
            onClick={() => gotoPage(pageIndex - 1)}
            disabled={!canPreviousPage}
            className="pagination-btn"
          >
            {"<"}
          </button>
          <span className="pagination-info">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>
          </span>
          <button
            onClick={() => gotoPage(pageIndex + 1)}
            disabled={!canNextPage}
            className="pagination-btn"
          >
            {">"}
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            className="pagination-btn"
          >
            {">>"}
          </button>
          {/* <select
    value={pageSize}
    onChange={(e) => {
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 20, 30, 50].map((size) => (
      <option key={size} value={size}>
        Show {size}
      </option>
    ))}
  </select> */}
        </div>

        <div className="pt-0px w-20">
          <FormControl fullWidth className="countrySelectField ml-0px">
            <Select
              labelId="demo-feedbackArea-select-label"
              id="demo-feedbackArea-select"
              label=""
              name="country"
              value={selectedCountry}
              onChange={getChart}
              displayEmpty
            >
              <MenuItem value="" disabled>
                {t("dropdowncountry")}
              </MenuItem>
              {countries?.map((x, index) => {
                return (
                  <MenuItem key={index} value={x.name} className="feedOption">
                    {x.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 mb-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={healthSytemColumnChart(healthSystemOptions)}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={crgColumnChart(crgOptions)}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4 mb-3">
              <HighchartsReact
                highcharts={Highcharts}
                options={unhlmColumnChart(unhlmOptions)}
              />
            </div>
          </div>
        </div> */}
        <Grid container spacing={2} className="feedbackgraphsgrid">
          {/* Graph 1 */}
          <Grid item xs={12} md={4}>
            <HighchartsReact
              highcharts={Highcharts}
              options={healthSytemColumnChart(healthSystemOptions)}
            />
          </Grid>

          {/* Graph 2 */}
          <Grid item xs={12} md={4}>
            <HighchartsReact
              highcharts={Highcharts}
              options={crgColumnChart(crgOptions)}
            />
          </Grid>

          {/* Graph 3 */}
          <Grid item xs={12} md={4}>
            <HighchartsReact
              highcharts={Highcharts}
              options={unhlmColumnChart(unhlmOptions)}
            />
          </Grid>
        </Grid>
      </Card>
      <Card className="mt-10px">
        <CardContent>
          <Typography className="feedbackInfo">{t("contactustext")}</Typography>
          <Grid container spacing={2} className="feedbackDetails mt-10px">
            <Grid item xs={12} lg={4}>
              <div className="d-flex justify-content-center align-items-center">
                <img src={phoneIcon} alt="phone"></img>
                <span>+237 242 01 01 16 / 661 599 290</span>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              {" "}
              <div className="d-flex justify-content-center align-items-center">
                <img src={messageIcon} alt="msg"></img>
                <span>info@draftb.org</span>
              </div>
            </Grid>
            <Grid item xs={12} lg={4}>
              {" "}
              <div className="d-flex justify-content-center align-items-center">
                <img src={boxIcon} alt="msg"></img>
                <span>2286 Yaoundé – Nsimeyong III (Carrefour TAM-TAM)</span>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

export default FeedbackPage;
