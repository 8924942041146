import React, { useEffect, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartMore from "highcharts/highcharts-more";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
  Alert,
} from "react-bootstrap";
require("highcharts/modules/exporting")(Highcharts);
HighchartMore(Highcharts);

const ChartComponent = ({
  options,
  className = "",
  callback,
  refrs,
  showBtns,
  toggleChartValues,
  hideDropdown,
  tooltip,
  yearList,
  yearSelect,
  SelectedMonth,
}) => {
  if (options) {
    if (options.xAxis) options.xAxis.gridLineWidth = 0;
    if (options.yAxis) options.yAxis.gridLineWidth = 0;
    options.colors = ["#4D9DE0"];
  }

  const [t] = useTranslation();
  const [activeTab, setActiveTab] = useState(["1", true]);

  const toggle = (tab) => {
    if (activeTab[0] !== tab) setActiveTab([tab, true]);
  };

  const handleSelect = (e) => {
    yearSelect(e);
  };

  return (
    <Card className={`bg-darkblue border-0 ${className}`}>
      <CardBody>
        {showBtns && (
          <div className="text-right my-2 mr-2">
            <Button
              variant="primary"
              size="sm"
              className="mx-2"
              onClick={(e) => toggleChartValues(false, refrs)}
            >
              #
            </Button>
            <Button
              variant="primary"
              // className="mx-2"
              size="sm"
              onClick={(e) => toggleChartValues(true, refrs)}
            >
              %
            </Button>
            {!hideDropdown && (
              <DropdownButton
                id="dropdown-basic-button"
                title="Select"
                variant="secondary"
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                <div>
                  <Dropdown.Header onClick={() => toggle("1")}>
                    {t('GENDER')}
                  </Dropdown.Header>
                  <div
                    className={
                      activeTab[0] == "1" && activeTab[1] ? "" : "hide"
                    }
                    style={{ maxHeight: "125px", overflowY: "auto" }}
                  >
                    <Dropdown.Item href="#/action-1">{t("Male")}</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">{t("Female")}</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">{t('Transgender')}</Dropdown.Item>
                  </div>
                </div>
                <div>
                  <Dropdown.Header onClick={() => toggle("2")}>
                    {t('AGE GROUPS')}
                  </Dropdown.Header>
                  <div
                    className={
                      activeTab[0] == "2" && activeTab[1] ? "" : "hide"
                    }
                    style={{ maxHeight: "125px", overflowY: "auto" }}
                  >
                    <Dropdown.Item href="#/action-1">{t('18 to 30')}</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">{t('31 to 45')}</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">{t('46 to 65')}</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">{t('66 or older')}</Dropdown.Item>
                  </div>
                </div>
                <div>
                  <Dropdown.Header onClick={() => toggle("3")}>
                    {t('KEY POPULATIONS')}
                  </Dropdown.Header>
                  <div
                    className={
                      activeTab[0] == "3" && activeTab[1] ? "" : "hide"
                    }
                    style={{ maxHeight: "125px", overflowY: "auto" }}
                  >
                    <Dropdown.Item href="#/action-1">
                      {t('Person living with HIV')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {t('Healthcare worker')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Miner</Dropdown.Item>
                    <Dropdown.Item href="#/action-4">Urban poor</Dropdown.Item>
                    <Dropdown.Item href="#/action-5">Rural poor</Dropdown.Item>
                    <Dropdown.Item href="#/action-6">Prisoner</Dropdown.Item>
                    <Dropdown.Item href="#/action-7">Refugee</Dropdown.Item>
                    <Dropdown.Item href="#/action-8">Migrant</Dropdown.Item>
                    <Dropdown.Item href="#/action-9">
                      {t('Person who uses drugs')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-10">
                      {t('Person with a disability')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-11">
                      {t('Ethnic minority/indigenous person')}
                    </Dropdown.Item>
                  </div>
                </div>
                <div>
                  <Dropdown.Header onClick={() => toggle("4")}>
                    {t('TB/NON TB')}
                  </Dropdown.Header>
                  <div
                    className={
                      activeTab[0] == "4" && activeTab[1] ? "" : "hide"
                    }
                    style={{ maxHeight: "125px", overflowY: "auto" }}
                  >
                    <Dropdown.Item href="#/action-1">
                      {t('Drug susceptible TB')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      {t('Multi-drug resistant TB')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      {t('Extensively-drug resistant TB')}
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-4">
                      {t('I do not have TB')}
                    </Dropdown.Item>
                  </div>
                </div>
              </DropdownButton>
            )}
            {yearList && (
              <DropdownButton
                id="dropdown-basic-button"
                title={SelectedMonth}
                variant="secondary"
                style={{ display: "inline-block", marginLeft: "10px" }}
              >
                <div style={{ maxHeight: "125px", overflowY: "auto" }}>
                  {yearList.map((y, index) => (
                    <Dropdown.Item
                      key={index}
                      onSelect={() => handleSelect(y)}
                      eventKey={y.value}
                    >
                      {y.label}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            )}
            {tooltip && (
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{tooltip}</Tooltip>}
              >
                <i className="fas fa-info-circle float-left"></i>
              </OverlayTrigger>
            )}
          </div>
        )}
        <div id="chart-wrapper" className="chart-wrapper">
          {options ? (
            <HighchartsReact
              highcharts={Highcharts}
              allowChartUpdate={true}
              containerProps={{ style: { width: "100%" } }}
              options={options}
              ref={refrs}
              callback={callback}
            />
          ) : (
            <Alert variant="warning" className="text-center my-3">
              {t('Loading')}
            </Alert>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default ChartComponent;
