import React, { useEffect, useState, useRef } from "react";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import ChartComponent from "../HighCharts/ChartComponents";
import { Alert } from "reactstrap";
import API from "../../services";
import * as URL from "../../services/urls";
import { trendchartdropdown } from "../../services/DropdownUtils";
import Chartloader from "../../services/Chartloader";
import moment from "moment";
import imgUrl from "../../assets/images/imgUrl";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    {props}
  </Tooltip>
);

function TrendChart({ uid }) {
  const elementRef = useRef(null);
  const [t] = useTranslation();
  const [loading, setLoading] = useState(true);
  const [trendChart, setTrendData] = useState(3);
  const [trendChartType, setTrendChartType] = useState("column");
  const [graphData, setgrpahData] = useState([]);
  let dummyArr = [];

  const [selectedValue, setSelectedValue] = useState("");
  const handleSelectChange = (event) => {
    const selectedItem = event.target.value;
    setSelectedValue(selectedItem);
    setTrendData(selectedItem);
  };

  useEffect(() => {
    let oRequest = {
      communityId: uid,
      languageId: 1,
      locationFilters: {},
      roleId: 53,
      isDefaultFilters: true,
    };
    let oReqTrendchartData = API.post(
      URL.serviceIndicatorWiseLineChart,
      oRequest
    );
    setLoading(true);
    Promise.all([oReqTrendchartData])
      .then(([oTrendchartData]) => {
        let trendchartDataRespose =
          oTrendchartData && oTrendchartData.data.data
            ? oTrendchartData.data.data.getPDFDataByCommunityIdData
              ? oTrendchartData.data.data.getPDFDataByCommunityIdData
              : oTrendchartData.data.data
            : [];
        setgrpahData(trendchartDataRespose);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [uid]);

  let chartOne = (item, chartType, flag) => {
    let seriesArr = [];
    if (Object.keys(item).length > 0) {
      let legendsArr = Object.keys(item.serviceWiseLineChart);

      if (Object.keys(item.serviceWiseLineChart).length > 0) {
        // let categoiesData = Object.keys(item.serviceWiseLineChart[legendsArr[0]]);

        var arrMonth = [];
        var arrMonthSec = [];
        let d = moment(new Date()).format("MMM YYYY");
        for (let i = 0; i < 12; i++) {
          var dateFrom = moment(d)
            .subtract(i, "months")
            .endOf("month")
            .format("MMM");
          arrMonthSec.push(dateFrom);
        }

        for (let i = 0; i < 12; i++) {
          var dateFrom = moment(d)
            .subtract(i, "months")
            .endOf("month")
            .format("MMM YYYY");
          arrMonth.push(dateFrom);
        }
        let translatedArr = arrMonth.map((ele) => {
          return t(ele);
        });

        let color = [
          "#4472c4",
          "#ed7d32",
          "#a5a5a5",
          "#ffc100",
          "#5b9bd5",
          "#71ad47",
        ];

        legendsArr &&
          legendsArr.length > 0 &&
          legendsArr.forEach((ele, index) => {
            let tempArr = [];

            arrMonthSec.forEach((data) => {
              if (item.serviceWiseLineChart[ele] !== null) {
                if (item.serviceWiseLineChart[ele][data] !== undefined) {
                  tempArr.push(item.serviceWiseLineChart[ele][data]);
                } else {
                  tempArr.push(0);
                }
              }
            });

            let obj = {};
            obj["name"] = t(ele);
            //obj["data"] = Object.values(item.serviceWiseLineChart[ele]);
            obj["data"] = tempArr.reverse();
            obj["color"] = color[index];
            seriesArr.push(obj);
            if (index <= legendsArr.length - 1) {
              dummyArr.push({
                name: ele,
              });
            }
          });

        // console.log(
        //   "dummyArr",
        //   dummyArr.push({
        //     name: t("All Indicators"),
        //   })
        // );
        // add list here

        const chart1 = {
          title: {
            text: "",
          },

          chart: {
            height: 360,
            type: chartType,
          },

          subtitle: {
            text: "",
          },

          yAxis: {
            title: {
              text: t("Number of People"),
            },
          },

          xAxis: {
            categories: translatedArr.reverse(),
          },

          legend: {
            // width: 500,
            // itemWidth: 250
          },

          exporting: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },

          plotOptions: {
            series: {
              label: {
                connectorAllowed: false,
              },
            },
          },

          series: seriesArr.slice(0, flag),
          responsive: {
            rules: [
              {
                condition: {
                  // maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    layout: "horizontal",
                    align: "center",
                    verticalAlign: "bottom",
                  },
                },
              },
            ],
          },
        };

        return chart1;
      } else {
      }
    }
  };

  return (
    <div
      ref={elementRef}
      className="col-6 col-md-12 col-lg-12 col-xl-6"
      id="chartContainer"
    >
      <Card className="text-left">
        <Card.Header>
          <span
            className="cardtitle"
            title={t(`TOP ${trendChart} CHALLENGES TREND OVER LAST YEAR`)}
          >
            {t(`TOP ${trendChart} CHALLENGES TREND OVER LAST YEAR`)}
          </span>
          <div className="tooltipdivmain">
            <div className="d-flex align-items-center justify-content-end">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  t(`Top ${trendChart} challenges trends over last year icon`)
                )}
              >
                <i className="fas fa-info-circle"></i>
              </OverlayTrigger>

              <div className="chaldropdiv trendchartdropdown">
                <select
                  id="trend-select"
                  value={selectedValue}
                  onChange={handleSelectChange}
                  style={{
                    backgroundColor: "#2196f3",
                    border: "0",
                    color: "#fff",
                    padding: "8px 12px",
                    cursor: "pointer",
                  }}
                >
                  {trendchartdropdown &&
                    trendchartdropdown.map((item, index) => (
                      <option key={index} value={item.value}>
                        {t(item.label)}
                      </option>
                    ))}
                </select>
              </div>
              {/* <div className="chaldropdiv trendchartdropdown">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-map">
                    {t(`Top ${trendChart == 3 ? "3" : "5"}`)}
                    <img
                      src={imgUrl.dropdownIcon}
                      className="dropdownicon"
                    ></img>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {trendchartdropdown &&
                      trendchartdropdown.map((item, index) => {
                        return (
                          <>
                            <Dropdown.Item
                              key={index}
                              onClick={() => {
                                setTrendData(item.value);
                              }}
                            >
                              {t(item.lable)}
                            </Dropdown.Item>
                          </>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="mb-10px trendcharts">
            <button
              className={
                trendChartType == "line"
                  ? "active nav-link chartswitchlink chartCategory"
                  : "nav-link chartswitchlink chartCategory"
              }
              onClick={() => {
                setTrendChartType("line");
              }}
            >
              {t("Trend Chart")}
            </button>
            <button
              className={
                trendChartType == "bar"
                  ? "active nav-link chartswitchlink chartCategory"
                  : "nav-link chartswitchlink chartCategory"
              }
              onClick={() => {
                setTrendChartType("bar");
              }}
            >
              {t("Bar Chart")}
            </button>
            <button
              className={
                trendChartType == "column"
                  ? "active nav-link chartswitchlink chartCategory"
                  : "nav-link chartswitchlink chartCategory"
              }
              onClick={() => {
                setTrendChartType("column");
              }}
            >
              {t("Column Chart")}
            </button>
          </div>
          <div id="natchartholder" className="natchartholder">
            {loading ? (
              <Chartloader />
            ) : loading == false &&
              graphData &&
              Object.keys(graphData).length > 0 &&
              Object.keys(graphData.serviceWiseLineChart).length > 0 ? (
              <ChartComponent
                options={chartOne(graphData, trendChartType, trendChart)}
                key={trendChartType + trendChart}
                showBtns={false}
                hideDropdown={true}
              />
            ) : (
              <Alert
                variant="warning"
                className="text-center alert-warning my-3"
              >
                {t("Data not available!")}
              </Alert>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TrendChart;
