import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

function Chartloader({isWidth = 50}) {
    return (
        <div className='spinner-loader'>
            <RotatingLines
            
                strokeColor="#36A98A"
                strokeWidth="5"
                animationDuration="0.75"
                width = {isWidth}
                visible={true}
            />
        </div>
    )
}

export default Chartloader