import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBKFQGWm6HPH8jr24AVoOTI42MKUwILL_Y",
  authDomain: "draftb.firebaseapp.com",
  projectId: "draftb",
  storageBucket: "draftb.firebasestorage.app",
  messagingSenderId: "1082023787605",
  appId: "1:1082023787605:web:95933cf285278364eaa8dc",
  measurementId: "G-G06N5EXXB9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };
